define("discourse/plugins/discourse-simple-unread/discourse/api-initializers/init-simple-unread", ["exports", "discourse/lib/api", "discourse/models/nav-item"], function (_exports, _api, _navItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.addDiscoveryQueryParam("unseen", {
      replace: true,
      refreshModel: true
    });
    api.addNavigationBarItem({
      name: "unseen",
      before: "top",
      customFilter: () => {
        return !!api.getCurrentUser();
      },
      customHref: (category, args) => {
        const path = _navItem.default.pathFor("latest", args);
        return `${path}?unseen=true`;
      },
      forceActive: (category, args, router) => {
        const queryParams = router.currentRoute.queryParams;
        return queryParams && Object.keys(queryParams).length === 1 && queryParams["unseen"] === "true";
      }
    });
  });
});